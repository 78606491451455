/* Customize your main colors in :root variables */
:root {
  --main-background-color: #000000;
  --card-background-color: #000000;
  --card-background-lighter-color: #000000;
  --main-text-color:#ffffff;
  --title-text-color:#E1FF00;
}

body{
  background-color:var(--main-background-color);
  font-family: 'Inter';
  font-size: 1em;
}

h1{
  margin-bottom: 5px;
}

h2{
  font-family: FieldWork;
  color: var(--main-text-color);
  font-size:3em;
  font-weight: 700;
  margin-bottom: 5px;
  margin-top: 30px;
  line-height: 1em;
}

h3{
font-weight: normal;
}

p{
  color: var(--main-text-color);
  font-size:0.875em;
  font-weight: 400;
  line-height: 1.8em;
  max-width: 500px;
  margin-bottom: 5px;
}

a{
  color:#E1FF00;
}
